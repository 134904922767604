$main-button-color: var(--main-button-color);
$text-color: white;
$border-radius: 6px;

.db3d-button-main{

    -moz-border-radius: $border-radius;
    -webkit-border-radius: $border-radius;
    border-radius: $border-radius;

    color: $text-color;
    background-color: var($main-button-color);
    font-weight: 500;
    font-size: 16px;

    border: none;
    outline: none;

    cursor: pointer;
   
    &:hover{

    }
    &:focus{

    }
    &:active{

    }
}

.db3d-button-search{


    
  

   
    

}






.btn {

}

.btn.btn-main:hover {
    border-color: var(--main-button-color);

    background-color: var(--main-button-color);
    border: none;
}



