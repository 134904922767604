.intiaro-cookie-banner {
  position: fixed;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%);
  width: 90vw;
  max-width: 1200px;
  z-index: 100000;
  color: #fff;
  background: rgba(0, 0, 0, 0.60);
  padding: 20px;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7px);
  border-radius: 8px;
  overflow: auto;
}
.intiaro-cookie-banner-content {
  width: 90%;
  max-width: 1600px;
  min-width: 200px;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.intiaro-cookie-banner-accept-and-close {
  display: block;
  flex: 0.2;
  max-width: 200px;
  min-width: 175px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.2px;
  background: #fff;
  color: var(--highlight-text-color);
  border-radius: 4px;
  border: none;
}
.intiaro-cookie-banner-accept-and-close-icon {
  margin-left: 10px;
}
.intiaro-cookie-banner-title {
  line-height: 19px;
  margin: 0px;
  text-align: left;
  display: flex;
  align-items: center;
}
.intiaro-cookie-banner-title-bold {
  font-weight: 600;
}
.intiaro-cookie-banner-icon {
  height: 19px;
  width: 19px;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 2px;
}
.intiaro-cookie-banner-text {
  max-width: 1600px;
  text-align: left;
  margin: auto;
  padding: 10px 3% 10px 0;
  font-weight: 400;
  flex: 0.8;
  margin-left: 0;
  line-height: 20px;
}
.intiaro-cookie-banner-consent {
  color: #fff;
  font-weight: 600;
  text-decoration: underline;
}
.intiaro-cookie-banner-consent:hover, .intiaro-cookie-banner-consent:visited,
.intiaro-cookie-banner-consent:active, .intiaro-cookie-banner-consent:focus {
  color: #27a4c2;
  text-decoration: underline;
}
.intiaro-cookie-banner-flexbox {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
@media (max-width: 880px) {
  .intiaro-cookie-banner-flexbox {
    flex-direction: column;
  }
}
@media (max-height: 500px) {
  .intiaro-cookie-banner-content {
    max-height: 80vh;
  }
}
@media (max-width: 350px) {
  .intiaro-cookie-banner-content {
    min-width: 300px;
  }
}
