@font-face {
    font-family: 'FurnitureIcons';
    src: url('../fonts/FurnitureIcons.eot?1japci');
    src: url('../fonts/FurnitureIcons.eot?1japci#iefix') format('embedded-opentype'), 
         url('../fonts/FurnitureIcons.ttf?1japci') format('truetype'), 
         url('../fonts/FurnitureIcons.woff?1japci') format('woff'), 
         url('../fonts/FurnitureIcons.svg?1japci#FurnitureIcons') format('svg');
    font-weight: normal;
    font-style: normal;
}

.f-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'FurnitureIcons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    display: inline-block;
}

.f-icon-dining-table:before {
    content: "\e900";
}

.f-icon-accessories:before {
    content: "\e901";
}

.f-icon-armchair:before {
    content: "\e902";
}

.f-icon-bar-set:before {
    content: "\e903";
}

.f-icon-bathroom:before {
    content: "\e904";
}

.f-icon-bedroom:before {
    content: "\e905";
}

.f-icon-bookcase:before {
    content: "\e906";
}

.f-icon-carpet:before {
    content: "\e907";
}

.f-icon-chair:before {
    content: "\e908";
}

.f-icon-children-room:before {
    content: "\e909";
}

.f-icon-kitchen:before {
    content: "\e90a";
}

.f-icon-lightning:before {
    content: "\e90b";
}

.f-icon-media-cabinet:before {
    content: "\e90c";
}

.f-icon-nightstand:before {
    content: "\e90d";
}

.f-icon-office:before {
    content: "\e90e";
}

.f-icon-retro:before {
    content: "\e90f";
}

.f-icon-shoe-cabinet:before {
    content: "\e910";
}

.f-icon-sofa:before {
    content: "\e911";
}

.f-icon-table:before {
    content: "\e912";
}

.f-icon-wardrobe:before {
    content: "\e913";
}
