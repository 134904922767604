

.error-message{
  margin: 15px 0px;
  color: lightcoral;
  font-size: 12px;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 604800s ease-in-out 0s;
  border-color: var(--input-focus-shadow-color);
  outline: 0;
  -webkit-box-shadow: 2px 2px rgba(0, 0, 0, 0);
  box-shadow: 2px 2px rgba(0, 0, 0, 0);
}

input:-webkit-autofill.form-input-valid,
input:-webkit-autofill:hover.form-input-valid,
input:-webkit-autofill:focus.form-input-valid,
textarea:-webkit-autofill.form-input-valid,
textarea:-webkit-autofill:hover.form-input-valid,
textarea:-webkit-autofill:focus.form-input-valid,
select:-webkit-autofill.form-input-valid,
select:-webkit-autofill:hover.form-input-valid,
select:-webkit-autofill:focus.form-input-valid {
  background-color: rgba(52, 140, 28, 0.1) !important;
  box-shadow: 0 0 0px 1000px rgba(52, 140, 28, 0.1) inset;
  -webkit-box-shadow: 0 0 0px 1000px rgba(52, 140, 28, 0.1) inset;
  -webkit-text-fill-color: rgba(52, 140, 28, 1);
  transition: background-color 604800s ease-in-out 0s;
  border-color: rgba(52, 140, 28, 1);
}

input:-webkit-autofill.form-input-error,
input:-webkit-autofill:hover.form-input-error,
input:-webkit-autofill:focus.form-input-error,
textarea:-webkit-autofill.form-input-error,
textarea:-webkit-autofill:hover.form-input-error,
textarea:-webkit-autofill:focus.form-input-error,
select:-webkit-autofill.form-input-error,
select:-webkit-autofill:hover.form-input-error,
select:-webkit-autofill:focus.form-input-error {
  background-color: rgba(178, 27, 72, 0.1) !important;
  box-shadow: 0 0 0px 1000px rgba(178, 27, 72, 0.1) inset;
  -webkit-box-shadow: 0 0 0px 1000px rgba(178, 27, 72, 0.1) inset;
  -webkit-text-fill-color: rgba(178, 27, 72, 1);
  transition: background-color 604800s ease-in-out 0s;
  border-color: rgba(178, 27, 72, 1);
}

input:focus::-webkit-input-placeholder {
  visibility:hidden; /*hide placeholder on focus*/
}

.important-button{
  font-weight: 500;
  font-size: 15px;
  margin-top: 10px;
}

.button-logo{
  width: 24px;
  margin-right: 5px;
}

hr{
  border-color:var(--dark-grey-color);
}

.clickable-text{
  font-family: 'Roboto';
  color: var(--dark-grey-color);
  font-size: 12px;
  font-weight: 900;
}

.clickable-text:hover{
  color: var(--main-color);
}

.form-title{
  font-size: 35px;
  text-align:center;
  padding-bottom: 24px;
}

.form-regular-text{
  font-size: 16px;
  text-align:center;
}

.form-small-text{
  font-size: 13px;
}

.form-hint{
  font-size: 13px;
  color:var(--light-grey-color);
  text-align:center;
  padding: 30px;
}

.round-corners {
  border-radius: 6px;
}

.new-form-input-field {
  height: 60px;
  text-align: center;
  border-radius: 6px;
  border-color: black;
  font-weight: 500;
  color: black;
}

.form-input-valid {
  box-shadow: 0 0 0px 1000px rgba(52, 140, 28, 0.1) inset;
  color: rgba(52, 140, 28, 1);
  border-color: rgba(52, 140, 28, 1);
}

.form-input-valid:focus {
  box-shadow: 0 0 0px 1000px rgba(52, 140, 28, 0.1) inset;
  border-color: rgba(52, 140, 28, 1);
}

.form-input-error{
  box-shadow: 0 0 0px 1000px rgba(178, 27, 72, 0.1) inset;
  color: rgba(178, 27, 72, 1);
  border-color: rgba(178, 27, 72, 1);
}

.form-input-error:focus{
  box-shadow: 0 0 0px 1000px rgba(178, 27, 72, 0.1) inset;
  border-color: rgba(178, 27, 72, 1);
}

.form-default-label {
  display: none;
}

.label-background {
  display: none;
}

input:focus + .label-background {
  position: absolute;
  top: -12px;
  height: 13px;
  background-color: white;
  display: block;
  padding: 0 5px;
  text-align: center;
  font-size: 13px;
  color: transparent;
}

input:focus + .label-background + .form-default-label {
  font-size:13px;
  font-family: 'Roboto';
  background-color: rgba(0, 0, 0, 0);
  padding: 0 3px;
  display: inline-block;
  position:absolute;
  width: 100%;
  text-align: center;
  top:-9px;
}

.form-invalid-label {
  color: rgba(178, 27, 72, 1);
}

.form-valid-label {
  color: rgba(52, 140, 28, 1);
}

.important-link{
  margin-left: 5px;
  font-weight: 500;
  color: var(--highlight-text-color);
}

.important-link:hover{
  cursor: pointer;
  color: var(--highlight-text-color);
}

.dotted-line{
  border: none;
  border-top: 1px dotted var(--light-grey-color )
}

.lock-icon{
  margin-right: 10px;
  margin-bottom: -5px;
  width: 18px;
  height: 24px;
}

.eye-icon{
  width: 18px;
  height: 14px;
  position: absolute;
  right: 20px;
  top: 24px;
  fill: none;
}

.bottom-bar{
  padding: 30px;
}

.form-group {
  display: flex;
  justify-content: center;
}

.width-limiter{
  max-width: 704px;
}

.error-tooltip {
    position:relative;
    border-bottom: none;
    text-align:left;
}

.error-tooltip .error-tooltip-body {
    min-width:200px;
    top:50%;
    left:100%;
    margin-left:20px;
    transform:translate(0, -50%);
    padding:10px 20px;
    color:#444444;
    background-color:#FFFFFF;
    font-weight:normal;
    font-size:13px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    display:none;
}

.error-tooltip:hover .error-tooltip-body {
  display:block;
  /* border-bottom:1px dotted #666; */
}

.error-tooltip .error-tooltip-body i.error-tooltip-arrow {
    position:absolute;
    top:50%;
    right:100%;
    margin-top:-12px;
    width:12px;
    height:24px;
    overflow:hidden;
}

.error-tooltip .error-tooltip-body i.error-tooltip-arrow::after {
    content:'';
    position:absolute;
    width:12px;
    height:12px;
    left:0;
    top:50%;
    transform:translate(50%,-50%) rotate(-45deg);
    background-color:#FFFFFF;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
}

@media (max-width: 999999px) {

  .error-tooltip .error-tooltip-body {
    min-width: 200px;
    top: -20px;
    left: 0;
    width: 100%;
    margin: 0px;
    transform: translate(0%, -100%);
  }

  .error-tooltip .error-tooltip-body i.error-tooltip-arrow {
    position: absolute;
    top: 100%;
    right: calc( 50% + -12px);
    margin-top: 0px;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }

  .error-tooltip .error-tooltip-body i.error-tooltip-arrow::after {
    content:'';
    position:absolute;
    width:12px;
    height:12px;
    left:0;
    top:0;
    transform:translate(50%,-50%) rotate(-45deg);
    background-color:#FFFFFF;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
  }

}
